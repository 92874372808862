import { useEffect, useState } from "react";

export const useFirstItem = (isEmpty) => {
  const [first, setFirst] = useState(false);
  const [isFirstItem, setIsFirstItem] = useState(false);

  useEffect(() => {
    isEmpty && setFirst(true);
  }, [isEmpty]);

  useEffect(() => {
    !first && setIsFirstItem(false);
  }, [first]);

  useEffect(() => {
    first && !isEmpty && setIsFirstItem(true);
    let timeout;
    first &&
      !isEmpty &&
      (timeout = setTimeout(() => {
        setFirst(false);
      }, 3000));

    return () => {
      clearTimeout(timeout);
    };
  }, [first, isEmpty]);

  return isFirstItem;
};
