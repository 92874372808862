import axios from "axios";
import { useContext } from "react";
import { API_URL } from "../constants";
import { MiddlewareContext } from "../middleware";

export default function useAxios() {
  const token = useContext(MiddlewareContext);
  const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token?.token}`,
    },
  });
  return axiosInstance;
}
