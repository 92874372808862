import { STATUSES } from "../constants";

export const filterCommonOrders = (orders) => {
  return orders?.filter((order) => {
    const { NEW, READY, FINISH } = STATUSES;
    const { items } = order || [];
    const noPans = !items?.every((group) => {
      return group?.data?.every((item) => item.pan);
    });
    const newItems = !!items?.filter((group) => {
      return group?.data?.filter((item) => {
        return item.status === NEW;
      })?.length;
    });

    return (
      noPans &&
      items?.length &&
      newItems &&
      order.status !== READY &&
      order.status !== FINISH
    );
  });
};

export const filterFreshOrders = (orders) =>
  orders.filter((order) => {
    const { IN_PROGRESS } = STATUSES;
    const { items } = order || [];

    const inprogressItems = !!items?.filter((group) => {
      return group?.data?.filter((item) => {
        return item.status === IN_PROGRESS;
      })?.length;
    });

    return order?.status === IN_PROGRESS && items?.length && inprogressItems;
  });

export const filterOrdersByStatus = (status, orders) => {
  return orders?.filter((order) => order.status === status);
};
