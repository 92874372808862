import { useEffect, useState } from "react";
import moment from "moment";
import momentTZ from 'moment-timezone';

export const useTimer = ({ date, pauseStartDate, pauseEndDate }) => {
  const [currentState, setCurrentState] = useState({
    time: momentTZ(moment().toISOString()).tz('America/Los_Angeles').format('HH:mm A'),
  });
  const [timePST, setTimePST] = useState({
    now: momentTZ(moment().toISOString()).tz('America/Los_Angeles').format('h:mm A')
  })
  let isPauseStarted = !!(pauseStartDate && !pauseEndDate);
  let isPauseEnded = !!(pauseStartDate && pauseEndDate);

  let startDate = date ? momentTZ(moment(date)).tz('America/Los_Angeles') : null;
  let pauseStart = pauseStartDate ? momentTZ(moment(pauseStartDate)).tz('America/Los_Angeles') : null;
  let pauseEnd = pauseEndDate ? momentTZ(moment(pauseEndDate)).tz('America/Los_Angeles') : null;


  let startPauseDiff =
      startDate && isPauseStarted
        ? pauseStart.diff(startDate, "milliseconds")
        : null;

  const getCurrentState = () => {
    const now = momentTZ(moment()).tz('America/Los_Angeles');

    let pauseDiff = isPauseEnded
      ? pauseEnd.diff(pauseStart, "milliseconds")
      : null;
    
    let startDateDiff =
      startDate && pauseDiff
        ? now.diff(startDate, "milliseconds") - pauseDiff
        : startDate
        ? now.diff(startDate, "milliseconds")
        : null;
    setCurrentState((prev) => ({
      ...prev,
      time: momentTZ(moment().toISOString()).tz('America/Los_Angeles').format('HH:mm A'),
      diff: startDateDiff ? momentTZ(moment(startDateDiff)).tz('America/Los_Angeles').format("mm:ss") : "",
    }));
    setTimePST((prev) => ({
      ...prev,
      now: momentTZ(moment().toISOString()).tz('America/Los_Angeles').format('h:mm A')
    }))
  };

  useEffect(() => {
    startPauseDiff && setCurrentState((prev) => ({
      ...prev,
      diff: momentTZ(moment(startPauseDiff)).tz('America/Los_Angeles').format("mm:ss"),
    }));
  }, [startPauseDiff])
  useEffect(() => {
    let interval = null;
    if (
      (!pauseStartDate && !pauseEndDate) ||
      (pauseStartDate && pauseEndDate)
    ) {
      interval = setInterval(() => {
        getCurrentState();
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [date, pauseStartDate, pauseEndDate]);

  return { ...currentState, ...timePST };
};
