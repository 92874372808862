import classes from "./index.module.scss";
import logo from "./images/logo.png";
import { useContext } from "react";
import { DataContext } from "../../context/context";

export default function EmptyScreen() {
  const { loading } = useContext(DataContext);

  if (loading) return null;

  return (
    <section className={classes.section}>
      <div className={classes.image}>
        <img src={logo} alt="Logo" width={135} height={138} />
      </div>
      <h3 className={classes.title}>All orders are completed</h3>
    </section>
  );
}
