import React, {useContext, useState} from "react";
import { PrintContext } from "../../../context/PrintContext/context";
import useAxios from "../../../hooks/useAxios";
import { Button } from "../../Button";
import AlertScreen from "../../AlertScreen";
import {toast} from "react-toastify";

export default function NextBtn({ id, isDisabled, itemsCount = null, currentItem = '' }) {
  const { enabledNextBtn, setEnabledNextBtn, setUseLabel,  } =
    useContext(PrintContext);
  const [load, setLoad] = useState(false)
  const axiosInstance = useAxios();
  const handleClick = async () => {
        setLoad(true)
      let timeOut = 0
      if(itemsCount === currentItem){
          timeOut = 1000
          toast.success('The order has been sent',{
              position: "top-center"
          })
      }
      setTimeout(async() => {
          try {
              const response =  await axiosInstance.post(`items/${id}/label`);
              if(response?.status === 201 || response?.status === 200){
                  setLoad(false)
              }
              if(response?.status === 400){
                  setLoad(false)
              }
              setLoad(false)
          }catch (err) {
              if(err){
                  setLoad(false)
              }
          }
      }, timeOut)

    // setEnabledNextBtn(false);
    // setUseLabel(true);
  };

  return (
      <>
        {load &&
            <AlertScreen isFirst={load} />
        }
        <Button
            disabled={!isDisabled}
            text="label"
            color="red"
            onClick={handleClick}
        />
      </>

  );
}
