import { AppRouter } from "./router";
import { Header } from "./components/Header";
import { DataState } from "./context/state";
import { useContext } from "react";
import { MiddlewareContext } from "./middleware";
import { useAppHeight } from "./hooks/useAppHeight";
import { PrintState } from "./context/PrintContext/state";
import { SuperviseState } from "./context/SuperviseContext/state";
import {ToastContainer} from "react-toastify";

function App() {
  const { logOut, role } = useContext(MiddlewareContext);
  const isOrderPage = role !== "EXPEDITOR";

  useAppHeight();

  return (
    <main className="app">
      <ToastContainer
          position="top-center"
          autoClose={3000}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="colored"
          limit={1}
      />
      <DataState>
        <SuperviseState>
          <PrintState>
            {role && (
              <Header  role={role} logOut={logOut} orderPage={isOrderPage} />
            )}
            <AppRouter role={role} />
          </PrintState>
        </SuperviseState>
      </DataState>
    </main>
  );
}

export default App;
