import { Pagination } from "../../components/Pagination";

import { Queue } from "../../components/Queue";
import { SingleOrderItem } from "../../components/SingleOrderItem";
import { SideBar } from "../../layouts/SideBar";
import "./index.scss";
import { useCommonHook } from "../../hooks/useCommonHook";
import { Pan } from "../Pan";

import EmptyScreen from "../../components/EmptyScreen";
import AlertScreen from "../../components/AlertScreen";
import { useFirstItem } from "../../hooks/useFirstItem";
import { MakeLabel } from "../../components/Actions/MakeLabel";
import NextBtn from "../../components/Actions/NextBtn";
import { Button } from "../../components/Button";
import { useContext } from "react";
import { PrintContext } from "../../context/PrintContext/context";
import { MiddlewareContext } from "../../middleware";
import Notification from "../../components/Notification";

export const SandwichView = () => {
  const { enabledNextBtn, useLabel, setUseLabel } = useContext(PrintContext);
  const { role } = useContext(MiddlewareContext);

  const { currentOrder, filteredOrders, currentItem } = useCommonHook();

  const isFirstItem = useFirstItem(!filteredOrders?.length);

  if (!currentOrder) return <EmptyScreen />;

  const currentGroup = currentOrder.items.find(
    (item) => item.groupId === currentItem.groupId
  );

  const isAllItemsReady =
    currentGroup.data.every((item) => item.pan) ||
    currentGroup.data.every((item) => item.pan === null);

  return (
    <section className="order-layout">
      <AlertScreen isFirst={isFirstItem} />
      <Notification isFirstItem={isFirstItem} />
      <Queue orders={filteredOrders} />
      <>
        <div className="main">
          {currentItem && (
            <SideBar
              className="order-item dessert-item"
              approvePrint={enabledNextBtn}
            >
              <SingleOrderItem orderTime={currentOrder?.orderDateTimeStart} currentItem={currentItem} />
            </SideBar>
          )}
          <div className="pagination-wrapper">
            <Pagination
              pan={false}
              items={currentOrder?.itemsCount}
              currentItem={currentItem?.itemUID}
            />
            {currentItem?.part && currentItem?.size ? (
              <Pagination pan={true} items={1} currentItem={1} />
            ) : null}
          </div>
        </div>
        {useLabel ? (
          <SideBar className="print-label sandwich_label">
            {/*<MakeLabel*/}
            {/*  group={currentGroup}*/}
            {/*  orderNumber={currentOrder.orderNumber}*/}
            {/*  clientName={currentOrder.customer}*/}
            {/*  isAllItemsReady={isAllItemsReady}*/}
            {/*  item={currentItem}*/}
            {/*  role={role}*/}
            {/*/>*/}
            {/*<Button*/}
            {/*  text="Pan"*/}
            {/*  color="red"*/}
            {/*  disabled={enabledNextBtn}*/}
            {/*  onClick={() => setUseLabel(false)}*/}
            {/*/>*/}
            <NextBtn
                id={currentGroup.groupId}
                isDisabled={isAllItemsReady}
                itemsCount={currentOrder?.itemsCount}
                currentItem={currentItem?.itemUID}
            />
          </SideBar>
        ) : (
          <Pan
            useLabel={useLabel}
            setUseLabel={setUseLabel}
            currentItem={currentItem}
          />
        )}
      </>
    </section>
  );
};
