import "./index.scss";
import ClearIcon from "../../assets/images/clear.png";
import CheckIcon from "../../assets/images/circle-check.png";
import {Button} from "../../components/Button";
import {SideBar} from "../../layouts/SideBar";
import {useContext, useEffect, useState} from "react";
import {DataContext} from "../../context/context";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const keyboardList = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
const templateString = "___";

const NumberComponent = ({number}) => <span>{number ? number : "_"}</span>;

export const Pan = ({currentItem, useLabel, setUseLabel, currentOrder = null}) => {
    const {updateItem} = useContext(DataContext);
    const [pan, setNumber] = useState(templateString);
    const [approve, setApprove] = useState(false);
    const currentPan = currentItem.type === "pizza" ? currentItem.part : currentOrder.itemsCount;
    const currentSize = currentItem.type === "pizza" ? currentItem.size : currentItem.itemUID;
    useEffect(() => {
        currentItem?.id && setNumber(templateString);
    }, [currentItem?.id]);

    const setNumberValue = (e) => {
        toast.dismiss();
        const dataNumber = e.target.dataset.number;
        dataNumber && setNumber((number) => number.replace("_", dataNumber));

    };
    const handleApprove = () => setApprove(true);

    const clearNumber = () => {
        const replaceLast = (str, pattern, replacement) => {
            const match =
                typeof pattern === "string"
                    ? pattern
                    : (str.match(new RegExp(pattern.source, "g")) || []).slice(-1)[0];
            if (!match) return str;
            const last = str.lastIndexOf(match);
            return last !== -1
                ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
                : str;
        };

        const findLast = pan.split("").filter((num) => num !== "_");

        setNumber((number) =>
            replaceLast(number, findLast[findLast.length - 1], "_")
        );
    };

    const setPanNumber = () => {
        let timeOut = 0
        if(currentPan === currentSize){
            timeOut = 1000;
            setApprove(false)
            toast.success('Order sent to the oven',{
                position: 'top-center'
            })
        }
        setTimeout(() => {
            updateItem("items", currentItem?.id, {
                pan,
                status: "in progress",
            })
            setApprove(false);
            useLabel === false && setUseLabel(true);
        }, timeOut)

    };

    useEffect(() => {
        if(pan === '000'){
            toast.error('Pan not can be 000', {
                position: "top-right"
            })
            setApprove(false)
        }
    }, [pan])
    return (
        <>

            <SideBar className="pan">
                {useLabel === false ? (
                    <Button
                        disabled={useLabel}
                        text="return"
                        color="red"
                        onClick={() => setUseLabel(true)}
                    />
                ) : null}
                <div className="">
                    <div className="pan-number">
                        PAN #{" "}
                        {pan.split("").map((num, idx) => (
                            <NumberComponent number={num} key={idx}/>
                        ))}
                    </div>
                    <div className="keyboard" onClick={setNumberValue}>
                        {keyboardList.map((keyValue) => (
                            <span
                                data-number={keyValue}
                                className="keyboard-item"
                                key={keyValue}
                            >
              {keyValue}
            </span>
                        ))}
                    </div>
                    <div className="actions">
                        <div className="half">
                            <Button
                                text={<img src={ClearIcon} alt="clear"/>}
                                color="red"
                                onClick={clearNumber}
                                disabled={pan === templateString}
                            />
                            <Button
                                text={<img src={CheckIcon} alt="check"/>}
                                color="red"
                                onClick={handleApprove}
                                disabled={pan.includes("_") || pan === '000'}
                            />
                        </div>
                        <Button
                            text="next"
                            color="red"
                            disabled={!approve}
                            onClick={setPanNumber}
                        />
                    </div>
                </div>
            </SideBar>
        </>
    );
};
