import './index.scss'

export const Pagination = ({ items, currentItem, pan }) => {
  return (
    <div className="pagination">
      <span>
        {pan ? 'PAN' : 'ITEM'} {currentItem} / {items}
      </span>
    </div>
  )
}
