import { useMemo, useState } from "react";
import { PrintContext } from "./context";

export const PrintState = ({ children }) => {
  const [enabledNextBtn, setEnabledNextBtn] = useState(false);
  const [useLabel, setUseLabel] = useState(true);

  const value = useMemo(() => {
    return {
      enabledNextBtn,
      setEnabledNextBtn,
      useLabel,
      setUseLabel,
    };
  }, [enabledNextBtn, useLabel]);

  return (
    <PrintContext.Provider value={value}>{children}</PrintContext.Provider>
  );
};
