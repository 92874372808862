import { STATUSES } from "../constants";

export const findCommonCurrentOrder = (orders) =>
  orders?.find((order) => {
    const { NEW, READY, FINISH } = STATUSES;
    const { items } = order || [];
    const noPans = !items?.every((group) => {
      return group?.data?.every((item) => item.pan);
    });

    const newItems = !!items?.reduce((acc, item) => {
      item?.data?.forEach((item) => {
        acc.push(item);
      });
      return acc.filter((item) => item?.status === NEW);
    }, [])?.length;

    return (
      noPans &&
      items?.length &&
      newItems &&
      order.status !== READY &&
      order.status !== FINISH
    );
  });

export const findCurrentOrderFreshIngredients = (orders) =>
  orders?.find((order) => {
    const { IN_PROGRESS } = STATUSES;

    if (
      order?.status === IN_PROGRESS &&
      order?.items?.length > 0 &&
      order?.items?.some((item) =>
        item?.data?.some((item) => item.status === IN_PROGRESS)
      )
    )
      return order;
  });
