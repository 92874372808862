import "./index.scss";
import { ReactComponent as QueueIcon } from "../../assets/images/queue.svg";
import { v4 as uuid } from "uuid";
import React from 'react'
export const Queue = ({ orders }) => {

  return (
    <div className="queue">
      <div className="queue-title">
        <QueueIcon />
        <span>QUEUE</span>
      </div>
      <div className="queue-body">
        {orders
          .map(({ orderNumber }) => orderNumber)
          .map((item, index) => (
            <React.Fragment   key={uuid()}>
                <div
                    className={`queue-body_item ${index === 0 ? "active" : ""}`}

                >
                    <div className="order_number">
                        <span className='dot' />#{item}
                    </div>
            </div>
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};
