import "./index.scss";

export const PizzaItem = ({ title, options, sauce }) => (
  <div className="pizza-item">
    <h4>{sauce ? `Sauce: ${title}` : title}</h4>
    {options && (
      <div className="ingredients">
        {options?.map((option, indx) => (
          <p className={option.name.includes("no") ? "red" : ""} key={indx}>
            + {option.name}
          </p>
        ))}
      </div>
    )}
  </div>
);
