import "./index.scss";
import { ReactComponent as TimerIcon } from "../../assets/images/timer.svg";
import { useTimer } from "../../hooks/useTimer";

export const Timer = ({ date, pauseStartDate, pauseEndDate, classes }) => {
  const { diff } = useTimer({ date, pauseStartDate, pauseEndDate });

  return (
    <div className={`timer ${classes ? classes : ''}`}>
      <TimerIcon />
      <span>{diff ? diff : "00:00"}</span>
    </div>
  );
};
