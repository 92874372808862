import "./index.scss";
import wings from "../../assets/images/wings.png";
import sandwich from "../../assets/images/sandwich.png";
import salad from "../../assets/images/salad.png";
// import sauce from "../../assets/images/sauce.png";
import iсeCream from "../../assets/images/ice-cream.png";
import {Timer} from "../Timer";
import {PizzaItem} from "../PizzaItem";

const getImage = (type) => {
  switch (type) {
    case "wings":
      return <img src={wings} alt="wings" />;
    case "desserts":
      return <img src={iсeCream} alt="ice cream" />;
    case "salads":
      return <img src={salad} alt="salad" />;
    case "sandwich":
      return <img src={sandwich} alt="sandwich" />;
    default:
      return "";
  }
};

export const SingleOrderItem = ({ currentItem, orderTime }) => (
  <>
    <div className="with-icon">
      {getImage(currentItem?.type)}
      <span>
        {currentItem?.title} x {currentItem?.size}
      </span>
      {orderTime &&
          <Timer date={orderTime} />
      }
    </div>

    {currentItem?.pan && (
      <span className="accepted-pan">PAN #{currentItem?.pan}</span>
    )}
    <span>{currentItem?.type}</span>
      <div className="list-wrapper">
        {currentItem?.list?.map((item) => (
            <PizzaItem
                item={currentItem}
                key={item?.id}
                title={item?.title}
                options={item?.options}
            />
        ))}
      </div>
  </>
);
