import {  useContext } from "react";
import { ROLES, STATUSES } from "../constants";
import { DataContext } from "../context/context";
import { filterCommonOrders } from "../helpers/filterOrders";
import { MiddlewareContext } from "../middleware";

export const useCommonHook = () => {
  const { READY, IN_PROGRESS } = STATUSES;
  const { FRESH, WINGS_SANDWICH } = ROLES;
  const { orders, currentOrder } = useContext(DataContext);
  const { role } = useContext(MiddlewareContext);
  const { items: orderItems } = currentOrder || [];
  const flattenItems = orderItems?.reduce((acc, item) => {
    item?.data?.forEach((item) => {
      acc.push(item);
    });
    return acc.filter((item) => item?.status !== READY);
  }, []);


  const currentItem = flattenItems?.find(({ status, pan }) =>
      role && (role === FRESH || role === WINGS_SANDWICH)
          ? pan && status === IN_PROGRESS
          : !pan && status !== READY
  )

  return {
    currentOrder,
    currentItem,
    filteredOrders: filterCommonOrders(orders),
    currentItemIndex: orderItems?.indexOf(currentItem),
  };
};
