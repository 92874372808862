import { useMemo, useState } from "react";
import { SuperviseContext } from "./context";

export const SuperviseState = ({ children }) => {
  const [supervise, setSupervise] = useState(false);

  const value = useMemo(() => {
    return {
      supervise,
      setSupervise,
    };
  }, [supervise]);

  return (
    <SuperviseContext.Provider value={value}>
      {children}
    </SuperviseContext.Provider>
  );
};
