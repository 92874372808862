import { useTimer } from '../../hooks/useTimer'
import classes from './index.module.scss'

export default function CurrentTime() {
    const { now,  } = useTimer({})

  return (
    <div className={classes["current-time"]}>{now}</div>
  )
}
