import React, { useMemo, useState, useEffect, useContext } from "react";
import { ROLES } from "../constants";
import {
  findCommonCurrentOrder,
  findCurrentOrderFreshIngredients,
} from "../helpers/findOrder";
import { MiddlewareContext } from "../middleware";
import { DataContext } from "./context";
import { useLocation } from "react-router-dom";
import useAxios from "../hooks/useAxios";
import NotificationSound from "../assets/images/notification.mp3";

export const DataState = ({ children }) => {
  const [orders, setOrders] = useState([]);
  const [scheduledOrders, setScheduledOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { socket, role } = useContext(MiddlewareContext);
  const expeditorRole = role === ROLES.EXPEDITOR;
  const freshRole = role === ROLES.FRESH;
  const wingsSandwichRole = role === ROLES.WINGS_SANDWICH;
  let { pathname } = useLocation();
  const [canPlay, setCanPlay] = useState(true);
  const isArchive = pathname === "/archive";
  const archivePath = isArchive
    ? "orders/expeditor/history"
    : "orders/expeditor";

  const axiosInstance = useAxios();

  const getList = async (entity, type = "orders") => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(entity);
      data && type === "orders" ? setOrders(data) : setScheduledOrders(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (role) {
      socket.off("connect").on("connect", function () {
        getList(expeditorRole ? archivePath : "orders");
        expeditorRole && getList("orders/expeditor/scheduled", "shedules");
      });
      socket.on("orders", function ({ success, data }) {
        if (success) {
          setOrders((prev) => [
            ...new Map([...prev, data].map((item) => [item.id, item])).values(),
          ]);
        }
      });
      socket.on("scheduled", function ({ success, data }) {
        if (success && expeditorRole) {
          setScheduledOrders((prev) => [...data]);
        }
      });
      socket.on("expeditor", function ({ success, data }) {
        if (success && expeditorRole) {
          setOrders((prev) => [...new Set([...prev, data])]);
        }
      });
      socket.on("photo", function ({ success, data }) {
        try {
          if (success && expeditorRole) {
            setOrders((prev) =>
              prev.map((order) => {
                if (order.id === data.orderId) {
                  return {
                    ...order,
                    items: order.items.map((item) => {
                      if (item.groupId === data.groupId) {
                        return {
                          ...item,
                          photo: data.photo,
                        };
                      }
                      return item;
                    }),
                  };
                }
                return order;
              }),
            );
          }
        } catch (e) {
          console.log(e);
        }
      });
      socket.on("exception", function (data) {
        console.log("event", data);
      });
      socket.on("disconnect", function () {
        console.log("Disconnected");
      });
    }
  }, [role, orders, scheduledOrders]);

  useEffect(() => {
    if (role) {
      socket.on("orders", function ({ success, data }) {
        if (data?.status === "new" && success && expeditorRole && canPlay) {
          new Audio(NotificationSound).play();
          setCanPlay(false);

          setTimeout(() => {
            new Audio(NotificationSound).play();
            setCanPlay(true);
          }, 1000);
        }
      });
    }
  }, [canPlay, role]);

  const updateItem = async (entity, id, updateData) =>
    await axiosInstance.put(`${entity}${id ? `/${id}` : ""}`, updateData);

  const attachPhoto = async (id, imageFile) => {
    const file = new FormData();
    const blob = await fetch(imageFile).then((res) => res.blob());
    file.append("file", blob);
    await axiosInstance.post(`items/${id}/photo`, file, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const value = useMemo(() => {
    return {
      orders,
      scheduledOrders,
      getList,
      updateItem,
      attachPhoto,
      currentOrder:
        freshRole || wingsSandwichRole
          ? findCurrentOrderFreshIngredients(orders)
          : findCommonCurrentOrder(orders),
      loading,
    };
  }, [orders, scheduledOrders]);

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
