import { lazy } from "react";
import { useRoutes } from "react-router-dom";
import Sandwich from "./pages/Sandwich";
const Archive = lazy(() => import("./pages/Archive"));
const Main = lazy(() => import("./pages/Main"));
const Login = lazy(() => import("./pages/Login"));
const Expeditor = lazy(() => import("./pages/Expeditor"));
const Fresh = lazy(() => import("./pages/Fresh"));
const PizzaWings = lazy(() => import("./pages/PizzaWings"));

const rolesRoutes = {
  EXPEDITOR: [
    { path: "*", element: <Expeditor /> },
    { path: "/archive", element: <Archive /> },
  ],
  PIZZA_MAKER: [{ path: "*", element: <PizzaWings /> }],
  SKINNY_PIZZA_MAKER: [{ path: "*", element: <PizzaWings /> }],
  WINGS_MAKER: [{ path: "*", element: <PizzaWings /> }],
  FRESH_INGREDIENTS_MAKER: [{ path: "*", element: <Fresh /> }],
  READY_ITEMS_MAKER: [{ path: "*", element: <Main /> }],
  SALAD_MAKER: [{ path: "*", element: <PizzaWings /> }],
  WINGS_SANDWICH_MAKER: [{ path: "*", element: <Main /> }],
  SANDWICH_MAKER: [{ path: "*", element: <Sandwich /> }],
};

export const Routes = ({ role }) => {
  return useRoutes(
    role
      ? rolesRoutes[role]
        ? rolesRoutes[role]
        : [
            {
              path: "*",
              element: (
                <div style={{ textAlign: "center" }}>Incorrect role</div>
              ),
            },
          ]
      : [{ path: "*", element: <Login /> }],
  );
};
