import "./index.scss";
import logo from "../../assets/images/logo.png";
import { ReactComponent as Exit } from "../../assets/images/exitIcon.svg";
import { Button } from "../Button";
import { Timer } from "../Timer";
import { useCommonHook } from "../../hooks/useCommonHook";
import { useContext, useEffect, useState } from "react";
import { SuperviseContext } from "../../context/SuperviseContext/context";
import { useLocation } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import CurrentTime from "../CurrentTime";

export const Header = ({ logOut, orderPage, role }) => {
  const { supervise, setSupervise } = useContext(SuperviseContext);
  const { pathname } = useLocation();
  const axiosInstance = useAxios();
  const { currentOrder, currentItem } = useCommonHook(role);
  const [userProfile, setUserProfile] = useState(null);
  const [isPaused, setIsPaused] = useState(false)

  const [pauseDate, setPauseDate] = useState('')
  const handleClick = () => setSupervise(!supervise);
  const isPauseEnabled = currentItem?.isButtonPauseEnabled && !userProfile?.isPauseEnabled;
  const buttonPauseColor = userProfile?.pauseItemId ? "green" : "red";
  const itemStartDate = currentItem?.history?.at(0)?.itemDateTimeStart;
  const pauseStartDate = currentItem?.history?.at(0)?.itemPauseDateTimeStart;
  const pauseEndDate = currentItem?.history?.at(0)?.itemPauseDateTimeEnd;
  const itemId = currentItem?.id;
  const pauseItemId = userProfile?.pauseItemId;

  const getUserProfile = async () => {
    if (role && role === "EXPEDITOR") return;
    const userProfile = await axiosInstance.get(`users/profile`);
    setUserProfile(userProfile.data);
  };

  const handlePause = async () => {
    if (role && role === "EXPEDITOR") return;
    const {data} = await axiosInstance.post(`items/${pauseItemId || itemId}/pause`);
    setPauseDate(data?.history[0]?.itemPauseDateTimeStart)
    setIsPaused(true)

    getUserProfile();
  };

  const handlePauseAfterStart = async () => {
    if (role && role === "EXPEDITOR") return;
    const {data} = await axiosInstance.post(`items/${pauseItemId || itemId}/pause`);
    setPauseDate(data?.history[0]?.itemPauseDateTimeStart)
    setIsPaused(false)
    setUserProfile(null)
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const getUserHistory = async () => {
    if (role && role === "EXPEDITOR") return;
    const {data} = await axiosInstance.get(`items/${pauseItemId}/history`);
    setPauseDate(data.itemPauseDateTimeStart)
    getUserProfile();
  };

  const handleStart = async () => {
    if (role && role === "EXPEDITOR") return;
    await axiosInstance.post(`items/${itemId}/start`);
    getUserProfile();
  };


  useEffect(  () => {
    const isPausedSessionStorage = localStorage.getItem('auth')
    const parse = JSON.parse(isPausedSessionStorage)
    if(!parse?.isPauseEnabled){
      setIsPaused(false)
    }
    itemId &&   getUserProfile();
    itemId && !itemStartDate  && handleStart();
  }, [itemId]);

  useEffect(() => {
    pauseItemId  && getUserHistory();
  }, [pauseItemId]);

  return (
    <header>
      { userProfile?.isPauseEnabled   &&
          <div className="paused_overlay">
            <div className="top_overlay_content">
              {userProfile?.isPauseEnabled ? <Timer date={pauseDate} /> : null}
            </div>
            <Button
                text="resume"
                disabled={isPauseEnabled}
                color={buttonPauseColor}
                onClick={handlePauseAfterStart}
            />
          </div>
      }
      { isPaused   && // костыль :(
          <div className="paused_overlay">
            <div className="top_overlay_content">
              {userProfile?.isPauseEnabled ? <Timer date={pauseDate} /> : null}
            </div>
            <Button
                text="resume"
                disabled={isPauseEnabled}
                color={buttonPauseColor}
                onClick={handlePauseAfterStart}
            />
          </div>
      }

      <div className="left-part">
        <img src={logo} alt="logo" className="logo" />
        {orderPage && currentOrder && (
          <>
            {currentItem && orderPage && (
              <Button
                text="pause"
                isShow={!isPaused}
                disabled={!isPauseEnabled}
                color={buttonPauseColor}
                onClick={handlePause}
              />
            )}
          </>
        )}
      </div>
      <CurrentTime />
      <div className="right-part">
        {role.replaceAll("_", " ")}
        {role === "EXPEDITOR" && pathname === "/" ? (
          <Button
            text="Supervise"
            color={supervise ? "green" : "red"}
            onClick={handleClick}
            className="button_max-content button_capitalize"
          />
        ) : null}
        <div>
          <Exit className="exit" onClick={logOut} />
        </div>
      </div>
    </header>
  );
};
