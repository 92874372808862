import "./index.scss";

export const SideBar = ({ className, children, approvePrint }) => (
  <aside
    className={`sidebar ${className || ""} ${
      approvePrint ? "sidebar_green" : ""
    }`}
  >
    {children}
  </aside>
);
