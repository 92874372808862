import classes from "./index.module.scss";

export default function AlertScreen({ isFirst }) {
  if (!isFirst) return null;
  return (
    <section className={classes.section}>
      <div className={classes.spinner}></div>
      <h3 className={classes.title}>Updating...</h3>
    </section>
  );
}
