import "./index.scss";

export const Button = ({
  text = "Button",
  color = "",
  className,
  withIcon,
  icon,
  isShow = true,
  ...rest
}) => (
  <button
    className={`button ${color ? "button-" + color : ""} ${
      className ? className : ""
    } ${isShow ? "" : "hide_btn"} ${withIcon ? "space_between" : ""}`}
    type="button"
    {...rest}
  >
    <p>{text}</p>
    {withIcon && <img src={icon} alt=" " />}
  </button>
);
