import NotificationSound from "../../assets/images/notification.mp3";
import {useEffect, useRef} from "react";


const Notification = ({isFirstItem}) => {
    const soundNotification = useRef(null);
    const refClick = useRef(null);

    const  playAudio = () => {
        refClick?.current.click()
    }

    const click = () => {
        new Audio(NotificationSound).play();
        setTimeout(() => {
            new Audio(NotificationSound).play();
        }, 1000)
    }


    useEffect(() => {
        isFirstItem && playAudio()
    }, [isFirstItem, soundNotification, refClick])

    return(
        <>
            <button
                style={{display: 'none'}}
                ref={refClick} onClick={click}>Play</button>
        </>
    )
}

export default Notification