import React, { createContext, useEffect, useMemo } from "react";
import { API_URL } from "./constants";
import { io } from "socket.io-client";
import useAxios from "./hooks/useAxios";
import moment from "moment";
import momentTZ from 'moment-timezone';




const MiddlewareContext = createContext();
const { Provider } = MiddlewareContext;
let socket = null;

const Middleware = ({ children }) => {
  const token = localStorage.getItem("auth");
  const parsedToken = token && JSON.parse(token);
  const axiosInstance = useAxios()

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };


  if (!socket && parsedToken?.accessToken) {
    console.log("------create socket connect--------");
    socket = io(API_URL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: parsedToken?.accessToken,
          },
        },
      },
    });
  }

  const logOut = () => {
    localStorage.removeItem("auth");
    window.location.href = "/";
  };

  const isAuth = () => {
    const token = parseJwt(parsedToken?.accessToken)?.exp
    const expDate = momentTZ(moment(new Date(token * 1000))).format('MM-DD-YYYY')
    const now = moment().format('MM-DD-YYYY')
    if(now === expDate){
      localStorage.removeItem("auth")
      setTimeout(() => {
        window.location.reload()
      }, 3000)
    }
  }


  useEffect(() => {
    isAuth()
    let prevCall = {};
    axiosInstance.interceptors.request.use(
      function (config) {
        //cancel duplicate GET requests
        if (JSON.stringify(config) === JSON.stringify(prevCall)) {
          return {
            ...config,
            cancelToken: new axiosInstance.CancelToken((cancel) =>
              cancel("Cancel repeated request")
            ),
          };
        } else {
          prevCall = config;
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (!error.response) return false;
        const { status } = error.response;

        switch (status) {
          case 401:
            logOut();
            break;
          case 403:
          case 422:
          case 404:
            break;
          default:
            break;
        }
        return Promise.reject(error);
      }
    );
  }, []);

  const value = useMemo(
    () => ({
      socket,
      role: parsedToken?.role,
      userId: parsedToken?.userId,
      logOut,
      isAuth,
      token: parsedToken?.accessToken,
    }),
    []
  );

  return <Provider value={value}>{children}</Provider>;
};

export { Middleware, MiddlewareContext };
