export const API_URL = process.env.REACT_APP_API_URL || "https://dev.api.kds.puzzle-pizza.agilistrysoft.com/";
// export const API_URL = "https://kdsdev.tk/";
// export const API_URL = "http://37.57.36.168:3000/";
export const ROLES = {
    EXPEDITOR: "EXPEDITOR",
    PIZZA: "PIZZA_MAKER",
    FRESH: "FRESH_INGREDIENTS_MAKER",
    READY: "READY_ITEMS_MAKER",
    WINGS: "WINGS_MAKER",
    SANDWICH: "SANDWICH_MAKER",
    SALAD: "SALAD_MAKER",
    WINGS_SANDWICH:"WINGS_SANDWICH_MAKER"
}
export const STATUSES = {
    NEW: "new",
    IN_PROGRESS: "in progress",
    READY: "ready",
    FINISH: "finish"
}

export const SOURCES = {
    square: 'In Person',
    kiosk: 'Kiosk',
    app: 'App'
}